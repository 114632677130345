
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton, IonButtons, IonSegment, IonSegmentButton, IonLabel, IonModal, IonIcon } from '@ionic/vue';
import recipes from "../../dummy/recipes.json";
import { ref } from "vue";
import { convert } from "html-to-text";
import { useRoute } from 'vue-router'
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { heart, heartOutline, hourglassOutline, timerOutline, barbellOutline, shareOutline, list, home, hammer } from 'ionicons/icons';
import { Haptics, ImpactStyle } from "@capacitor/haptics";


export default  {
    name: 'Recipes',
    components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonBackButton, IonButtons, IonSegment, IonSegmentButton, IonLabel, IonModal, IonIcon },

    setup() {
        const isOpenRef = ref(false);
        const setOpen = (state: boolean) => isOpenRef.value = state;
        return { isOpenRef, setOpen, heart, heartOutline, hourglassOutline, timerOutline, barbellOutline, shareOutline, list, home, hammer };
    },
    data() {
        return {
            activeSegment: "info",
            liked: false,
        }
    },
    methods: {
        toggleLike() {
            Haptics.impact({ style: ImpactStyle.Heavy });
            this.liked = !this.liked;
        },
        segmentChanged(event) {
            this.activeSegment = event.detail.value;
            Haptics.impact({ style: ImpactStyle.Light });
        },
        getPlatform() {
            return Capacitor.getPlatform();
        },
        share() {
            Haptics.impact({ style: ImpactStyle.Light });
            Share.share({
                title: this.recipe.name,
                text: this.recipe.shortDescription,
                url: 'https://dev.ploetzblog.de/detail/id=' + this.recipe.id,
                dialogTitle: 'Teile mit deinen Brotfreunden',
            });
        }
    },
    computed: {
        recipe(): any {
            return recipes.filter(r => r._id['$oid'] === useRoute().params.recipeID).map((r) => {
                return {
                    ...r,
                    id: r._id['$oid'],
                    name: r.name?.find((v) => v.lang === "de-DE")?.value,
                    tradeName: r.tradeName?.find((v) => v.lang === "de-DE")?.value,
                    shortDescription: convert(r.shortDescription?.find((v) => v.lang === "de-DE")?.value),
                    description: convert(r.description?.find((v) => v.lang === "de-DE")?.value),
                }
            })[0] || {};
        }
    },
}
